import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as IconContact } from "assets/images/icons/nav-contact.svg";
import { ReactComponent as IconDocs } from "assets/images/icons/nav-docs.svg";
import { ReactComponent as IconArrowRight } from "assets/images/icons/top-right-arrow.svg";
import useRoutes from "hooks/useRoutes";
import Maybe from "./Maybe";
import useToolkit from "hooks/useToolkit";

export const NavigationLinks = () => {
  const { dashboardRoutes } = useRoutes();

  return (
    <div className="h-full flex flex-col justify-between">
      <div>
        {dashboardRoutes
          .slice(0, 11)
          .map(
            (route, idx) =>
              route.isShown && <SidebarRouteItem key={idx} route={route} />
          )}
      </div>

      <div>
        <div className="">
          <NavLink
            to="https://docs.payshiga.com"
            target="_blank"
            rel="noopener noreferrer"
            className="sidebar-links !justify-between "
          >
            <IconDocs className="sidebar-links-icon " />
            <div>Read our docs </div>
            <IconArrowRight className="sidebar-links-icon !mr-0" />
          </NavLink>
        </div>
        {dashboardRoutes
          .slice(11)
          .map(
            (route, idx) =>
              route.isShown && <SidebarRouteItem key={idx} route={route} />
          )}

        <NavLink to="mailto:support@payshiga.com" className="sidebar-links">
          <IconContact className="sidebar-links-icon" />
          <div>Contact us</div>
        </NavLink>
        <div className="h-4 hidden md:block"></div>
      </div>
    </div>
  );
};

const SidebarRouteItem = ({ route }) => {
  const { pathname } = useLocation();
  const { title, path, permission } = route;
  const isActive = path?.includes("settings")
    ? pathname?.includes("settings")
    : path?.includes("manage-bills")
    ? pathname?.includes("manage-bills")
    : path?.includes("docs")
    ? pathname?.includes("docs")
    : pathname?.includes(path);
  const { kybVerified } = useToolkit();
  const isWebhooks = title?.includes("Webhooks");
  const isRefundAndChargebacks = title?.includes("Refunds & Chargebacks");

  return (
    <div className="w-full pl-4 pr-3 space-y-4">
      <Maybe condition={!permission}>
        <div
          className={`h-11 flex items-center cursor-not-allowed text-shiga-gray-300 text-opacity-40 pl-3`}
        >
          <img
            alt={title}
            className="mr-2"
            style={{ opacity: isActive && kybVerified ? "100%" : "40%" }}
            src={`/sidebar-icons/${
              isWebhooks
                ? "webhooks"
                : isRefundAndChargebacks
                ? "refunds"
                : title.toLowerCase()
            }-${isActive && kybVerified ? "active" : "inactive"}.svg`}
          />
          <div>{title}</div>
        </div>
      </Maybe>

      <Maybe condition={permission}>
        <NavLink to={path}>
          <div
            className={`h-11 flex items-center hover:bg-[#f0f0f0] hover:rounded-lg pl-3 
          ${
            isActive
              ? "text-shiga-purple-500 font-medium"
              : "text-shiga-gray-300"
          }`}
          >
            <img
              alt={title}
              className="mr-2"
              src={`/sidebar-icons/${
                isWebhooks
                  ? "webhooks"
                  : isRefundAndChargebacks
                  ? "refunds"
                  : title.toLowerCase()
              }-${isActive ? "active" : "inactive"}.svg`}
            />
            <div>{title}</div>
          </div>
        </NavLink>
      </Maybe>
    </div>
  );
};

export default NavigationLinks;
