import { SetStateAction, useEffect, useState } from "react";
import { RootState } from "store/store";
import useToolkit from "hooks/useToolkit";
import CenterModal from "components/common/CenterModal";
import { ReadIcon } from "./NotificationsRenderer";
import moment from "moment";

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  selectedNotification: any;
};

const ReadNotificationModal = ({
  isOpen,
  setIsOpen,
  selectedNotification,
}: Props) => {
  return (
    <CenterModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      headerTitle={
        <div className="mr-2.5">
          <ReadIcon />
        </div>
      }
      isButtonLoading={false}
    >
      <div className="w-full relative">
        <div className="">
          <div className={`mb-1.5 text-2xl font-bold text-shiga-dark-300 `}>
            {selectedNotification?.title}
          </div>

          <div className="text-shiga-gray-300 mb-3">
            {moment(selectedNotification?.createdAt).format(
              "MMM D, YYYY [at] h:mma"
            )}
          </div>

          <div className="text-md text-shiga-dark-200 max-h-[300px] overflow-y-scroll w-auto scrollbar-hide">
            {selectedNotification?.body}
          </div>
        </div>
      </div>
    </CenterModal>
  );
};

export default ReadNotificationModal;
