import { ReactComponent as IconEdit } from "assets/images/icons/Edit.svg";
import { ReactComponent as IconRemove } from "assets/images/icons/Close.svg";
import { ReactComponent as IconFileUpload } from "assets/images/icons/file-upload.svg";
import { useEffect, useRef, useState } from "react";
import Maybe from "components/common/Maybe";
import useToolkit from "hooks/useToolkit";
import { FileUploader } from "react-drag-drop-files";

type Props = {
  onFileChange: (file: any) => void;
  onDelete: () => void;
  disabled: boolean;
  initialSrc: any;
  placeholder?: string;
};

const ProductImageHandler = ({
  onFileChange,
  onDelete,
  disabled,
  initialSrc,
  placeholder,
}: Props) => {
  const { toastError } = useToolkit();
  const [logo, setLogo] = useState<any>(null);
  const [img, setImg] = useState<any>(null);
  const imgRef = useRef<any>(null);

  const logoRef = useRef<HTMLInputElement>(null);
  const fileTypes = ["jpeg", "png", "jpg", "pdf", "csv"];

  const handleChange = (file: any) => {
    if (!disabled) {
      setLogo(file);
      onFileChange(file);

      // imgRef.current = URL.createObjectURL(file);
      // setImg(URL.createObjectURL(file))

      if (file.type.startsWith("image/")) {
        imgRef.current = URL.createObjectURL(file);
        setImg(URL.createObjectURL(file));
      } else {
        imgRef.current = file.name;
        setImg(file.name);
      }
    }
  };
  useEffect(() => {
    if (initialSrc !== null) {
      if (typeof initialSrc === "string") {
        setImg(initialSrc);
        setLogo(initialSrc);
      } else {
        // Handle images and other file types separately
        if (initialSrc.type && initialSrc.type.startsWith("image/")) {
          setImg(URL.createObjectURL(initialSrc));
        } else {
          setImg(initialSrc.name);
        }
        setLogo(initialSrc);
      }
    } else {
      setImg(null);
    }
  }, [initialSrc]);

  //   useEffect(() => {
  //     if (initialSrc !== null) {
  //       if (typeof initialSrc === "string") {
  //         setImg(initialSrc);
  //         setLogo(initialSrc);
  //       } else {
  //         setImg(URL.createObjectURL(initialSrc));
  //         setLogo(initialSrc);
  //       }
  //     } else {
  //       setImg(null);
  //     }
  //   }, [initialSrc]);

  return (
    <div
      className={`w-full h-[140px] border-2 border-shiga-gray-50 rounded-2xl ${
        logo === null ? "px-5 py-10" : ""
      }`}
    >
      <Maybe condition={logo !== null}>
        <div className="relative w-full h-full">
          {/* <img
            alt="logo"
            src={img}
            className="rounded-2xl object-cover max-h-full"
          /> */}
          {img && img.startsWith("blob:") ? (
            // Display image preview for image files
            <img
              alt="File preview"
              src={img}
              className="rounded-2xl object-cover max-h-full"
            />
          ) : (
            // Display file name for non-image files
            <div className="text-sm text-gray-700 text-center p-2">
              {img || "Unsupported file type"}
            </div>
          )}

          <input
            type="file"
            ref={logoRef}
            // accept=".png, .jpg, .jpeg"
            accept={fileTypes.map((type) => `.${type}`).join(", ")}
            style={{ display: "none" }}
            onChange={(e: any) => handleChange(e.target.files[0])}
          />

          <Maybe condition={!disabled}>
            <IconRemove
              data-tooltip-id="dp-remove"
              data-tooltip-content="Remove File"
              onClick={() => {
                setImg(null);
                setLogo(null);
                onDelete();
              }}
              className="w-8 h-8 p-1 bg-black bg-opacity-50 absolute z-[1] bottom-2 
               left-2 rounded-full hover:bg-opacity-100 cursor-pointer outline-none svg-fill-white"
            />

            <IconEdit
              onClick={() => logoRef?.current?.click()}
              data-tooltip-content={logo ? "" : "Change File"}
              className="w-8 h-8 p-1.5 bg-black bg-opacity-50 
                     absolute z-[1] bottom-2 right-2 rounded-full hover:bg-opacity-100 
                     cursor-pointer outline-none"
            />
          </Maybe>
        </div>
      </Maybe>

      <Maybe condition={logo === null}>
        <div className="w-full h-full relative rounded-3xl flexed flex-col">
          <FileUploader
            ref={logoRef}
            multiple={false}
            classes="file_upload_zone"
            handleChange={handleChange}
            types={fileTypes}
            onTypeError={(err: any) => toastError(err)}
            onSizeError={(err: any) => toastError(err)}
          >
            <div className="flex flex-col items-center justify-center">
              <div className="w-16 h-16 flexed">
                <IconFileUpload className="m-auto w-full h-full" />
              </div>
              {placeholder && (
                <div className="mt-3 text-sm text-center text-gray-300 ">
                  {placeholder}
                </div>
              )}
            </div>
          </FileUploader>
        </div>
      </Maybe>
    </div>
  );
};

export default ProductImageHandler;
