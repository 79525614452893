import { RootState } from "store/store";
import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";
import { toast } from "react-toastify";

interface InitialState {

    createDeviceLoading: boolean;
    createDeviceData: any;

    getDevicesLoading: boolean;
    getDevicesData: any;

    deleteDeviceLoading: boolean;
    deleteDeviceData: any;

    createOperatorLoading: boolean;
    createOperatorData: any;

    getOperatorsLoading: boolean;
    getOperatorsData: any;

    deleteOperatorLoading: boolean;
    deleteOperatorData: any;

    updateDeviceLoading: boolean;
    updateDeviceData: any;

    operatorTokenLoading: boolean;
    operatorTokenData: any;

    createAgentLoading: boolean;
    createAgentData: any;

    getAgentsLoading: boolean;
    getAgentsData: any;

    deleteAgentsLoading: boolean;
    deleteAgentsData: any;

    agentTokenLoading: boolean;
    agentTokenData: any;

    deviceTrxLoading: boolean;
    deviceTrxData: any;

    deviceMetricsLoading: boolean;
    deviceMetricsData: any;

    deviceTrxDetailsLoading: boolean;
    deviceTrxDetailsData: any;
}


const initialState: InitialState = {

    createDeviceLoading: false,
    createDeviceData: null,

    getDevicesLoading: false,
    getDevicesData: null,

    deleteDeviceLoading: false,
    deleteDeviceData: null,

    createOperatorLoading: false,
    createOperatorData: null,

    getOperatorsLoading: false,
    getOperatorsData: null,

    deleteOperatorLoading: false,
    deleteOperatorData: null,

    updateDeviceLoading: false,
    updateDeviceData: null,

    operatorTokenLoading: false,
    operatorTokenData: null,

    createAgentLoading: false,
    createAgentData: null,

    getAgentsLoading: false,
    getAgentsData: null,

    deleteAgentsLoading: false,
    deleteAgentsData: null,

    agentTokenLoading: false,
    agentTokenData: null,

    deviceTrxLoading: false,
    deviceTrxData: null,

    deviceMetricsLoading: false,
    deviceMetricsData: null,

    deviceTrxDetailsLoading: false,
    deviceTrxDetailsData: null,
}


export default function reducer(state = initialState, { type, payload }: any): InitialState {
    switch (type) {

        case actions.GET_DEVICE_TRX_DETAILS_START:
            return {
                ...state,
                deviceTrxDetailsLoading: true,
                deviceTrxDetailsData: null,
            }

        case actions.GET_DEVICE_TRX_DETAILS_DONE:
            return {
                ...state,
                deviceTrxDetailsLoading: false,
                deviceTrxDetailsData: {
                    ...state.deviceTrxDetailsData,
                    ...payload
                },
            }

        case actions.GET_DEVICE_TRX_DETAILS_FAILED:
            errorReducer(payload)
            return {
                ...state,
                deviceTrxDetailsLoading: false,
                deviceTrxDetailsData: payload,
            }

        case actions.GET_DEVICE_TRX_METRICS_START:
            return {
                ...state,
                deviceMetricsLoading: true,
                deviceMetricsData: null,
            }

        case actions.GET_DEVICE_TRX_METRICS_DONE:
            return {
                ...state,
                deviceMetricsLoading: false,
                deviceMetricsData: payload,
            }

        case actions.GET_DEVICE_TRX_METRICS_FAILED:
            errorReducer(payload)
            return {
                ...state,
                deviceMetricsLoading: false,
                deviceMetricsData: payload,
            }

        case actions.GET_DEVICE_TRX_START:
            return {
                ...state,
                deviceTrxLoading: true,
                deviceTrxData: null,
            }

        case actions.GET_DEVICE_TRX_DONE:
            return {
                ...state,
                deviceTrxLoading: false,
                deviceTrxData: payload,
            }

        case actions.GET_DEVICE_TRX_FAILED:
            errorReducer(payload)
            return {
                ...state,
                deviceTrxLoading: false,
                deviceTrxData: payload,
            }

        case actions.GENERATE_AGENT_TOKEN_START:
            return {
                ...state,
                agentTokenLoading: true,
                agentTokenData: null,
            }

        case actions.GENERATE_AGENT_TOKEN_DONE:
            toast(payload?.message)
            return {
                ...state,
                agentTokenLoading: false,
                agentTokenData: payload,
            }

        case actions.GENERATE_AGENT_TOKEN_FAILED:
            errorReducer(payload)
            return {
                ...state,
                agentTokenLoading: false,
                agentTokenData: payload,
            }

        case actions.DELETE_TERMINAL_AGENT_START:
            return {
                ...state,
                deleteAgentsLoading: true,
                deleteAgentsData: null,
            }

        case actions.DELETE_TERMINAL_AGENT_DONE:
            toast(payload?.message)
            return {
                ...state,
                deleteAgentsLoading: false,
                deleteAgentsData: { success: true },
            }

        case actions.DELETE_TERMINAL_AGENT_FAILED:
            errorReducer(payload)
            return {
                ...state,
                deleteAgentsLoading: false,
                deleteAgentsData: payload,
            }

        case actions.CREATE_TERMINAL_AGENT_START:
            return {
                ...state,
                createAgentLoading: true,
                createAgentData: null,
            }

        case actions.CREATE_TERMINAL_AGENT_DONE:
            toast(payload?.message)
            return {
                ...state,
                createAgentLoading: false,
                createAgentData: payload,
            }

        case actions.CREATE_TERMINAL_AGENT_FAILED:
            errorReducer(payload)
            return {
                ...state,
                createAgentLoading: false,
                createAgentData: payload,
            }

        case actions.GET_TERMINAL_AGENTS_START:
            return {
                ...state,
                getAgentsLoading: true,
                getAgentsData: null,
            }

        case actions.GET_TERMINAL_AGENTS_DONE:
            return {
                ...state,
                getAgentsLoading: false,
                getAgentsData: payload,
            }

        case actions.GET_TERMINAL_AGENTS_FAILED:
            errorReducer(payload)
            return {
                ...state,
                getAgentsLoading: false,
                getAgentsData: payload,
            }

        case actions.GENERATE_OPERATOR_TOKEN_START:
            return {
                ...state,
                operatorTokenLoading: true,
                operatorTokenData: null,
            }

        case actions.GENERATE_OPERATOR_TOKEN_DONE:
            toast(payload?.message)
            return {
                ...state,
                operatorTokenLoading: false,
                operatorTokenData: payload,
            }

        case actions.GENERATE_OPERATOR_TOKEN_FAILED:
            errorReducer(payload)
            return {
                ...state,
                operatorTokenLoading: false,
                operatorTokenData: payload,
            }

        case actions.GET_TERMINAL_OPERATORS_START:
            return {
                ...state,
                getOperatorsLoading: true,
                getOperatorsData: null,
            }

        case actions.GET_TERMINAL_OPERATORS_DONE:
            return {
                ...state,
                getOperatorsLoading: false,
                getOperatorsData: payload,
            }

        case actions.GET_TERMINAL_OPERATORS_FAILED:
            errorReducer(payload)
            return {
                ...state,
                getOperatorsLoading: false,
                getOperatorsData: payload,
            }

        case actions.CREATE_TERMINAL_OPERATOR_START:
            return {
                ...state,
                createOperatorLoading: true,
                createOperatorData: null,
            }

        case actions.CREATE_TERMINAL_OPERATOR_DONE:
            toast(payload?.message)
            return {
                ...state,
                createOperatorLoading: false,
                createOperatorData: payload,
            }

        case actions.CREATE_TERMINAL_OPERATOR_FAILED:
            errorReducer(payload)
            return {
                ...state,
                createOperatorLoading: false,
                createOperatorData: payload,
            }

        case actions.DELETE_TERMINAL_OPERATOR_START:
            return {
                ...state,
                deleteOperatorLoading: true,
                deleteOperatorData: null,
            }

        case actions.DELETE_TERMINAL_OPERATOR_DONE:
            toast(payload?.message)
            return {
                ...state,
                deleteOperatorLoading: false,
                deleteOperatorData: payload,
            }

        case actions.DELETE_TERMINAL_OPERATOR_FAILED:
            errorReducer(payload)
            return {
                ...state,
                deleteOperatorLoading: false,
                deleteOperatorData: payload,
            }

        case actions.DELETE_TERMINAL_DEVICE_START:
            return {
                ...state,
                deleteDeviceLoading: true,
                deleteDeviceData: null,
            }

        case actions.DELETE_TERMINAL_DEVICE_DONE:
            toast(payload?.message)
            return {
                ...state,
                deleteDeviceLoading: false,
                deleteDeviceData: payload,
            }

        case actions.DELETE_TERMINAL_DEVICE_FAILED:
            errorReducer(payload)
            return {
                ...state,
                deleteDeviceLoading: false,
                deleteDeviceData: payload,
            }

        case actions.UPDATE_TERMINAL_DEVICE_START:
            toast('Updating device status')
            return {
                ...state,
                updateDeviceLoading: true,
                updateDeviceData: null,
            }

        case actions.UPDATE_TERMINAL_DEVICE_DONE:
            toast('Device status updated')
            return {
                ...state,
                updateDeviceLoading: false,
                updateDeviceData: payload,
            }

        case actions.UPDATE_TERMINAL_DEVICE_FAILED:
            errorReducer(payload)
            return {
                ...state,
                updateDeviceLoading: false,
                updateDeviceData: payload,
            }

        case actions.GET_TERMINAL_DEVICES_START:
            return {
                ...state,
                getDevicesLoading: true,
                getDevicesData: null,
            }

        case actions.GET_TERMINAL_DEVICES_DONE:
            return {
                ...state,
                getDevicesLoading: false,
                getDevicesData: payload,
            }

        case actions.GET_TERMINAL_DEVICES_FAILED:
            errorReducer(payload)
            return {
                ...state,
                getDevicesLoading: false,
                getDevicesData: payload,
            }

        case actions.CREATE_TERMINAL_DEVICE_START:
            return {
                ...state,
                createDeviceLoading: true,
                createDeviceData: null,
            }

        case actions.CREATE_TERMINAL_DEVICE_DONE:
            toast(payload?.message)
            return {
                ...state,
                createDeviceLoading: false,
                createDeviceData: payload,
            }

        case actions.CREATE_TERMINAL_DEVICE_FAILED:
            errorReducer(payload)
            return {
                ...state,
                createDeviceLoading: false,
                createDeviceData: payload,
            }

        case actions.RESET_TERMINAL_PARAMS:
            return {
                ...state,
                createDeviceData: null,
                updateDeviceData: null,
                createOperatorData: null,
                operatorTokenData: null,
                deleteOperatorData: null,
                createAgentData: null,
                deleteAgentsData: null,
                agentTokenData: null,
            }

        default:
            return state;
    }
}


export const getRefundsStore = (state: RootState) => state.refunds;