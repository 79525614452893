import SearchBox from "components/common/SearchBox";
import Pagination from "components/common/Pagination";
import CustomTableFilter from "components/common/CustomTableFilter";


const FilterPagination = ({ data }: { data: any }) => {

   return (
      <div className="mb-4 flex items-center justify-start lg:justify-between flex-wrap lg:flex-nowrap">
         <div className="w-full lg:w-2/4">
            <CustomTableFilter
               dropdownClass=''
               filters={[
                  {
                     title: "Type",
                     name: "type",
                     dataType: "select",
                     options: [
                        {
                           display: "Show all",
                           payload: "-",
                        },
                        {
                           display: "Card payment",
                           payload: "CARD_PAYMENT",
                        },
                        {
                           display: "Bank transfer",
                           payload: "BANK_TRANSFER",
                        },
                     ],
                  },
                  {
                     title: "Custom Date",
                     dataType: "date-range",
                     options: {
                        from: {
                           name: "startDate",
                           placeholder: "Start Date",
                        },
                        to: {
                           name: "endDate",
                           placeholder: "End Date",
                        },
                     },
                  },
               ]}
            />
         </div>

         <div className="w-full lg:w-2/4 flex items-center justify-between pt-5 md:pt-0 lg:space-x-5 lg:justify-end">
            <Pagination meta={data} />

            <div className="lg:pb-[26px]">
               <SearchBox
                  name='search'
                  placeholder="Search"
                  extraUrlParams='page=1'
               />
            </div>
         </div>
      </div>
   )
}

export default FilterPagination 