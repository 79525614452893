import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { PaginationResponse } from "types/data-interface";

interface Account {
  id: string;
  name: string;
  number: string;
  bank: string;
  bankCode: string;
  currency: string;
  isVirtual: boolean;
  createdAt: string;
  updatedAt: string;
  businessId: string;
  reference: string;
  customerId: string;
}

interface State {
  loadingAccounts: boolean;
  accounttList: Account[];
  accounttListData: PaginationResponse<Account[]>;
  ngnDepositAccountLoading: boolean;
  ngnDepositAccount: any;
}

const initialState: State = {
  loadingAccounts: false,
  accounttList: [],
  accounttListData: {
    meta: {
      size: 0,
      totalItems: 0,
      nextPage: 0,
      previousPage: 0,
    },
    payload: [],
  },
  ngnDepositAccountLoading: false,
  ngnDepositAccount: null,
};

export default function reducer(state: State = initialState, action: any) {
  switch (action.type) {
    case actions.GET_NGN_DEPOSIT_ACCOUNT_START:
      state = {
        ...state,
        ngnDepositAccountLoading: true,
        ngnDepositAccount: action.payload,
      };
      return state;

    case actions.GET_NGN_DEPOSIT_ACCOUNT_DONE:
      state = {
        ...state,
        ngnDepositAccountLoading: false,
        ngnDepositAccount: action.payload,
      };
      return state;

    case actions.GET_NGN_DEPOSIT_ACCOUNT_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        ngnDepositAccountLoading: false,
        ngnDepositAccount: null,
      };
      return state;

    case actions.GET_ISSUED_ACCOUNTS:
      state = {
        ...state,
        loadingAccounts: false,
        accounttList: action.payload.data,
        accounttListData: action.payload.data,
      };
      return state;
    case actions.GET_ISSUED_ACCOUNTS_START:
      state = {
        ...state,
        loadingAccounts: true,
      };
      return state;
    case actions.GET_ISSUED_ACCOUNTS_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        loadingAccounts: false,
      };
      return state;
    default:
      return state;
  }
}
