const PATHS = {
  TRANSFER: "/transactions/make-a-transfer",
  TRANSFER_BENEFICIARY: "/transactions/make-a-transfer/beneficiary",
  TRANSFER_AMOUNT: "/transactions/make-a-transfer/amount",
  TRANSFER_AMOUNT_TO: "/transactions/make-a-transfer/convert/",
  TRANSFER_CONFIRM: "/transactions/make-a-transfer/confirm",
  ISSUED_ACCOUNTS: "/accounts",
  TRANSFER_BULK_PAYMENT: "/transactions/make-a-transfer/bulk-payment",
  CONVERT_MONEY: "/transactions/convert-money",
  CONVERT_MONEY_CONFIRM: "/transactions/convert-money/confirm",
  TRANSACTION_HOME: "/transactions",
  DASHBOARD: "/dashboard",
  ACCOUNTS: "/accounts",

  CARDS: {
    CARDS_HOMEPAGE: "/cards",
  },

  BILLS: {
    AIRTIME: "/manage-bills/buy-airtime",
  },

  PAYMENTS: {
    PAYMENT_LINK: "/payments/payment-link",
  },

  AUTH: {
    LOGIN: "/login",
    // FORGOT_PASSWORD: "/auth/forgot-password",
    // RESET_PASSWORD: "/auth/reset-password",
    // REGISTER: "/auth/register",
    // REGISTER_BUSINESS: "/auth/register/business",
    // REGISTER_BUSINESS_STEP_2: "/auth/register/business/step-2",
    // REGISTER_BUSINESS_STEP_3: "/auth/register/business/step-3",
    // REGISTER_BUSINESS_STEP_4: "/auth/register/business/step-4",
  },

  TRANSFERS: {
    MAKE_A_TRANSFER: "/transactions/make-a-transfer",
  },

  TRANSACTIONS: {
    TRANSACTIONS_HOMEPAGE: "/transactions",
  },

  SETTLEMENTS: {
    SETTLEMENTS_HOMEPAGE: "/settlements",
    SETTLEMENT_DETAILS_PAGE: "/settlements/details/",
  },

  REFUNDS: {
    REFUNDS_HOMEPAGE: "/refunds-and-chargebacks",
  },

  TEAMS: {
    ACCEPT_INVITE: "/teams/accept-invite",
  },

  SETTINGS: {
    USER_PROFILE: "/settings/profile",
    BUSINESS_PROFILE: "/settings/business-profile",
    SETTLEMENT_ACCOUNTS: "/settings/settlement-accounts",
    TEAM: "/settings/team",
    FEES_EARNINGS: "/settings/fees-earnings",
    PAYMENT_METHODS: "/settings/payment-methods",
    NOTIFICATIONS: "/settings/notifications",
    KEYS_WEBHOOKS: "/settings/keys-webhooks",
  },

  WEBHOOKS: "/api-and-webhooks",

  TERMINALS: "/terminals",

  DOCS: "/docs",
};

export default PATHS;
