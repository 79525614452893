import React, { ChangeEvent, useEffect, useState } from "react";
import CustomAmountField from "components/custom/CustomAmountField";
import { ReactComponent as IconWallet } from "assets/images/icons/transfer-wallet.svg";
import { useForm } from "react-hook-form";
import { getCurrentBalance } from "lib/business";
import { toast } from "react-toastify";
import { defaultWallet, Wallet } from "types/wallet";
import { useSharedStore } from "zustand-store";
import { ReactComponent as IconInfoFillYellow } from "assets/images/icons/Info-Yellow.svg";
import { useAppSelector } from "store/hooks";
import { getDashboard } from "store/dashboard/reducer";
import { ButtonLoader, SelectInput } from "components/common/form";

interface FormValues {
  amount: string;
  currency: string;
  refundType: string;
}

const initialFormValues: FormValues = {
  amount: "",
  currency: "NGN",
  refundType: "",
};

const refundTypesOptions = [
  { label: "Full refund", value: "FULL_REFUND" },
  { label: "Partial refund", value: "PARTIAL" },
];

const AcceptChargeback = () => {
  const { currencies } = useSharedStore();
  const { wallets } = useAppSelector(getDashboard);
  const [formV, setFormV] = useState<FormValues>(initialFormValues);
  const {
    control,
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<FormValues>();
  const [chargebackAmount, setChargebackAmount] = useState("");
  const [currentWallet, setCurrentWallet] = useState<Wallet>(defaultWallet);
  const updateAmount = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    setChargebackAmount(value);
    setFormV((prevFormV) => ({
      ...prevFormV,
      amount: value,
    }));
  };

  const updateCurrentBalance = (currency: string) => {
    getCurrentBalance(currency)
      .then((data: any) => {
        setCurrentWallet(data.data);
      })
      .catch((err) => {
        toast.error(`Unable to get balance: ${err.message}`);
      });
  };

  useEffect(() => {
    const curr = formV.currency;
    if (curr) {
      updateCurrentBalance(curr);
    }
  }, [formV.currency]);

  const inputExceedsTrxAmount =
    currentWallet?.balance !== undefined &&
    Number(chargebackAmount) > currentWallet?.balance;
  const insufficientAmount =
    chargebackAmount.length > 0 && Number(chargebackAmount) < 100;

  const walletString = sessionStorage.getItem("wallet");
  const wallet = JSON.parse(walletString!);

  const currencyCodesInWallet = wallet.map(
    (walletItem: any) => walletItem?.currency
  );
  const filteredCurrencies = currencies.filter((currency) =>
    currencyCodesInWallet.includes(currency.currency)
  );

  const currenciesList = filteredCurrencies.map((item: any) => ({
    label: item.currency,
    value: item.currency,
    flagSrc: `/flags/${item.currency.toLowerCase()}.svg`,
  }));

  const isFormValid = () => {
    return formV.amount && formV.currency && formV.refundType;
  };

  const amountMessage = `The minimum amount you can purchase is ${
    currentWallet?.currency === "USD"
      ? "1 USD"
      : currentWallet?.currency === "GBP"
      ? "1 GBP"
      : "100 NGN"
  } `;

  return (
    <div className="pb-12 mb-16 ">
      <div className=" rounded-3xl bg-gradient-to-t from-[#FFFBF5] to-[#FFFBF5] bg-[#FFF7EB] flex flex-col items-start gap-4 p-4 md:p-6 w-full">
        <div className="flex items-start gap-x-2">
          <IconInfoFillYellow className="flex-none w-[12px] h-[12px] mt-1" />
          <p className="font-inter text-[#523000] text-[12px]  tracking-tighter">
            By accepting this chargeback, you’re instructing us to process a
            refund that will be deducted from your next payout
          </p>
        </div>
        <div className="flex items-start gap-x-2">
          <IconInfoFillYellow className="flex-none w-[12px] h-[12px] mt-1" />
          <p className="font-inter text-[#523000] text-[12px]  tracking-tighter">
            Once an agent is created for your business, it cannot be edited—only
            deleted.
          </p>
        </div>
      </div>
      <div>
        <div className=" mb-[24px] z-10">
          <SelectInput
            required
            label="How much should we refund?"
            value={formV.refundType}
            onChange={(e: any) => {
              setFormV({ ...formV, refundType: e.target.value });
            }}
          >
            <option value="">Select an option</option>
            {refundTypesOptions?.map((item) => {
              return (
                <option value={item?.label} key={item?.label}>
                  {item.label}
                </option>
              );
            })}
          </SelectInput>
        </div>
        <div className="relative z-0">
          <CustomAmountField
            leftLabel="You send"
            isFieldRequired
            showCurrenciesDropdown
            handleSelection={(currency: string) =>
              setFormV({ ...formV, currency })
            }
            setInputAmount={updateAmount}
            inputError={insufficientAmount || inputExceedsTrxAmount}
            errorText={
              insufficientAmount
                ? amountMessage
                : inputExceedsTrxAmount
                ? "You cannot make a purchase more than your available balance"
                : amountMessage
            }
            flagIconUrl={
              currencies.find((curr) => curr.currency === formV.currency)
                ?.icon || ""
            }
            dropdownCurrencies={currenciesList}
            inputAmount={formV.amount}
            transactionCurrency={formV?.currency || ""}
            rightLabel={
              <p className="text-shiga-dark flex items-center font-inter">
                You have &nbsp;
                <span className="text-shiga-purple font-medium">
                  {`${Number(currentWallet?.balance).toLocaleString()} ${
                    currentWallet?.currency ?? ""
                  }`}
                </span>
              </p>
            }
          />
        </div>

        <div className="flex items-end justify-end ">
          <ButtonLoader
            type="submit"
            //   loading={}
            disabled={
              !isFormValid() || inputExceedsTrxAmount || insufficientAmount
            }
            className="btn btn-lg btn-block btn-primary mt-6 bg-shiga-green-300 w-auto "
          >
            Accept Chargeback
          </ButtonLoader>
        </div>
      </div>
    </div>
  );
};

export default AcceptChargeback;
