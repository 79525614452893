import { useEffect, useRef } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import AppLayout from "components/layouts/AppLayout";
import AuthLayout from "components/layouts/AuthLayout";
import AuthenticationLayer from "components/layouts/AuthenticationLayer";
import NullLayout from "components/layouts/NullLayout";
import RegisterLayout from "components/layouts/RegisterLayout";
import SettingsLayout from "components/layouts/SettingsLayout";

import Error401 from "./pages/errors/Error401";
import Error404 from "./pages/errors/Error404";

import ForgotPassword from "pages/auth/forgotPassword";
import Login from "pages/auth/login";
import LoginMFA from "pages/auth/loginMFA";
import LoginOTP from "pages/auth/loginOTP";
import Register from "pages/auth/register";
import RegisterAccountType from "pages/auth/registerAccountType";
import RegisterBusinessInfo from "pages/auth/registerBusinessInfo";
import ResetPassword from "pages/auth/resetPassword";
import ResetPasswordSuccess from "pages/auth/resetPasswordSuccess";
import Index from "pages/index";

import Dashboard from "pages/dashboard/index";
import BusinessProfile from "pages/settings/businessProfile";
import FeesEarnings from "pages/settings/feesEarnings";
import MyProfile from "pages/settings/myProfile";
import Notifications from "pages/settings/notifications";
// import APIKeysWebhooks from "pages/settings/apiKeysWebhooks";
import PaymentMethods from "pages/settings/paymentMethods";
import SettlementAccounts from "pages/settings/settlementAccounts";
import Team from "pages/settings/team";

import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import PATHS from "NavigationRoute";
import { Toaster } from "components/ui/sonner";
import EmptyIssuedAccount from "pages/accounts/EmptyIssuedAccounts";
import BillsLayout from "pages/bills";
import EcommerceLayout from "pages/ecommerce";
import BuyAirtime from "pages/bills/BuyAirtime";
import BuyElectricity from "pages/bills/BuyElectricity";
import CableTV from "pages/bills/CableTV";
import MobileData from "pages/bills/MobileData";
import Card from "pages/card";
import CreateCard from "pages/card/CreateCard";
import { ReviewCardAndCreate } from "pages/card/Review-card-and-create";
import { SelectedCardDetails } from "pages/card/SelectedCard";
import { AddMoney } from "pages/dashboard/add-money";
import { PaymentsContainer } from "pages/payments";
import { CreateInvoice } from "pages/payments/invoice/CreateInvoice";
import { InvoiceContainer } from "pages/payments/invoice/InvoiceContainer";
import { Paytution } from "pages/payments/pay-tution";
import { PayTutionSteps } from "pages/payments/pay-tution/steps";
import { PaymentLinks } from "pages/payments/payment-links";
import { CreatePaymentLink } from "pages/payments/payment-links/create";
import { ViewOnePaymentLink } from "pages/payments/payment-links/ViewPaymentLink";
import NetwordLoading from "components/common/NetworkLoading";
import TransactionsHomepage from "pages/transactions/TransactionsHomepage";
import { PAYMENT_PATHS } from "pages/payments/paths";
import { GiftCard } from "pages/payments/gift-cards";
import { PurhaseGiftCard } from "pages/payments/gift-cards/purchase";
import { Refund } from "pages/refunds";
import ConvertMoney from "pages/convert/ConvertMoney";
import ConvertMoneyConfirm from "pages/convert/ConvertMoneyConfirm";
import { ViewInvoice } from "pages/payments/invoice/ViewInvoice";
import { useSharedStore } from "zustand-store";
import DirectDeposit from "pages/dashboard/direct-deposit";
import CustomToastRenderer from "components/common/custom-toast/CustomToastRenderer";
import AcceptInvite from "pages/settings/AcceptInvite";
import Products from "pages/ecommerce/Products";
import OrderTable from "pages/ecommerce/table/OrderTable";
import DeliveryAgent from "pages/ecommerce/DeliveryAgent";
import CreateProduct from "pages/ecommerce/CreateProduct";
import ViewProduct from "pages/ecommerce/ViewProduct";
import SettlementsHomepage from "pages/settlements/SettlementsHomepage";
import SettlementDetailsOverview from "pages/settlements/SettlementDetailsOverview";
import WebhooksHomepage from "pages/webhooks/WebhooksHomepage";
import TerminalsHomepage from "pages/terminals/TerminalsHomepage";

library.add(fas, far);

const withRouter = (Component: any) => {
  function ComponentWithRouterProp(props: any) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }
  return ComponentWithRouterProp;
};

const ScrollToTopComponent = () => {
  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      //componentDidMount
      mounted.current = true;
    } else {
      //componentDidUpdate
      window.scrollTo(0, 0);
    }
  });
  return null;
};
export const ScrollToTop = withRouter(ScrollToTopComponent);

function App() {
  const { initSharedData } = useSharedStore();

  useEffect(() => {
    // if (userLogin) {
    // }
    initSharedData();

    // eslint-disable-next-line
  }, []);

  return (
    <BrowserRouter>
      <NetwordLoading />
      <ScrollToTop />
      <Toaster />
      <CustomToastRenderer />

      <Routes>
        <Route path="/" element={<Index />} />

        <Route element={<AuthLayout />}>
          <Route element={<AuthenticationLayer userAuth="loggedOut" />}>
            <Route path="/login" element={<Login />} />
            <Route path="/login/verify" element={<LoginMFA />} />
            <Route path="/login/verify/otp" element={<LoginOTP />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route
              path="/reset-password/success"
              element={<ResetPasswordSuccess />}
            />
          </Route>

          <Route path="/invite/:id" element={<AcceptInvite />} />
        </Route>

        <Route element={<RegisterLayout />}>
          <Route element={<AuthenticationLayer userAuth="loggedOut" />}>
            <Route path="/register" element={<Register />} />
            <Route
              path="/register/account-type"
              element={<RegisterAccountType />}
            />
            <Route
              path="/register/business-info"
              element={<RegisterBusinessInfo />}
            />
          </Route>
        </Route>

        <Route element={<AppLayout />}>
          <Route element={<AuthenticationLayer userAuth="loggedIn" />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/add-money" element={<AddMoney />} />
            <Route
              path="/dashboard/direct-deposit"
              element={<DirectDeposit />}
            />
            <Route
              path={PATHS.TRANSACTIONS.TRANSACTIONS_HOMEPAGE}
              element={<TransactionsHomepage />}
            />

            <Route
              path={PATHS.SETTLEMENTS.SETTLEMENTS_HOMEPAGE}
              element={<SettlementsHomepage />}
            />

            <Route
              element={<SettlementDetailsOverview />}
              path={`${PATHS.SETTLEMENTS.SETTLEMENT_DETAILS_PAGE}:id`}
            />

            <Route path="/refunds-and-chargebacks" element={<Refund />} />
            {/* <Route path={PATHS.TRANSFER} element={<Transfer />} />
            <Route
              path={PATHS.TRANSFER_BULK_PAYMENT}
              element={<BulkTransfer />}
            /> */}
            {/* <Route
              path={PATHS.TRANSFER_BENEFICIARY}
              element={<Payout />}
            />
            <Route path={PATHS.TRANSFER_AMOUNT} element={<AmountToSend />} />

            <Route
              path={PATHS.TRANSFER_CONFIRM}
              element={<TransferDetails />}
            /> */}
            <Route path={PATHS.CONVERT_MONEY} element={<ConvertMoney />} />

            <Route
              path={PATHS.CONVERT_MONEY_CONFIRM}
              element={<ConvertMoneyConfirm />}
            />

            <Route
              path={PATHS.ISSUED_ACCOUNTS}
              element={<EmptyIssuedAccount />}
            />

            <Route path={PAYMENT_PATHS.INDEX} element={<PaymentsContainer />}>
              <Route path={PAYMENT_PATHS.INDEX} element={<PaymentLinks />} />
              <Route
                path={PAYMENT_PATHS.PAYMENT_LINK}
                element={<PaymentLinks />}
              />
              <Route
                path={PAYMENT_PATHS.CREATE_PAYMENT_LINK}
                element={<CreatePaymentLink />}
              />
              <Route path={PAYMENT_PATHS.TUITION} element={<Paytution />} />
              <Route
                path={PAYMENT_PATHS.TUITION_STEPS}
                element={<PayTutionSteps />}
              />
              <Route
                path={PAYMENT_PATHS.INVOICE}
                element={<InvoiceContainer />}
              />
              <Route
                path={PAYMENT_PATHS.CREATE_INVOICE}
                element={<CreateInvoice />}
              />
              <Route
                path={PAYMENT_PATHS.VIEW_ONE_PAYMENT_LINK}
                element={<ViewOnePaymentLink />}
              />
              <Route
                path={PAYMENT_PATHS.VIEW_INVOICE}
                element={<ViewInvoice />}
              />
              <Route path={PAYMENT_PATHS.GIFT_CARD} element={<GiftCard />} />
              <Route
                path={PAYMENT_PATHS.GIFT_CARD_PURCHASE}
                element={<PurhaseGiftCard />}
              />
            </Route>
            <Route path="/cards" element={<Card />} />
            <Route path="/cards/create-a-card" element={<CreateCard />} />
            <Route
              path="/cards/create-a-card/review-details-and-create"
              element={<ReviewCardAndCreate />}
            />
            <Route path="/cards/:id" element={<SelectedCardDetails />} />
            {/* <Route path="/ojah" element={<ComingSoon />} /> */}

            <Route path="/manage-bills" element={<BillsLayout />}>
              <Route
                path="/manage-bills/buy-airtime"
                element={<BuyAirtime />}
              />
              <Route
                path="/manage-bills/mobile-data"
                element={<MobileData />}
              />
              <Route path="/manage-bills/cable-tv" element={<CableTV />} />
              <Route
                path="/manage-bills/buy-electricity"
                element={<BuyElectricity />}
              />
            </Route>
            <Route path="/ojah" element={<EcommerceLayout />}>
              <Route path="/ojah/products" element={<Products />} />
              <Route path="/ojah/products/:id" element={<ViewProduct />} />
              <Route
                path="/ojah/products/create-product"
                element={<CreateProduct />}
              />
              <Route path="/ojah/orders" element={<OrderTable />} />
              <Route path="/ojah/delivery-agent" element={<DeliveryAgent />} />
            </Route>

            <Route element={<SettingsLayout />}>
              <Route path="/settings/profile" element={<MyProfile />} />
              <Route
                path="/settings/business-profile"
                element={<BusinessProfile />}
              />
              <Route
                path="/settings/settlement-accounts"
                element={<SettlementAccounts />}
              />
              <Route path="/settings/team" element={<Team />} />
              <Route
                path="/settings/fees-earnings"
                element={<FeesEarnings />}
              />
              <Route
                path="/settings/payment-methods"
                element={<PaymentMethods />}
              />
              <Route
                path="/settings/notifications"
                element={<Notifications />}
              />

              {/* <Route
                path="/settings/keys-webhooks"
                element={<APIKeysWebhooks />}
              /> */}
            </Route>

            <Route path={PATHS.WEBHOOKS} element={<WebhooksHomepage />} />

            <Route path={PATHS.TERMINALS} element={<TerminalsHomepage />} />
          </Route>
        </Route>

        <Route path="/" element={<NullLayout />}>
          <Route path="/401" element={<Error401 />} />
          <Route path="/404" element={<Error404 />} />
        </Route>
        <Route
          path="/settings"
          element={<Navigate to="/settings/profile" replace />}
        />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
