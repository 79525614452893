import CenterModal from 'components/common/CenterModal'
import React, { SetStateAction, useState } from 'react'
import moment from 'moment';
import CustomNotePad from 'components/custom/CustomNotePad';
import useInlineRouter from 'components/common/useInlineRouter';
import { TransactionNarration } from './DeviceTrxUtils';
import TransactionDetailTabs from './TransactionDetailTabs';


type Props = {
   isOpen: boolean;
   setIsOpen: React.Dispatch<SetStateAction<boolean>>;
   transaction: any;
   details: any;
   selected: any;
}

const apiTrxNavItems: InlineRouterNavItemProps[] = [
   {
      title: 'More Details',
      navValue: 'details',
   },
   {
      title: 'Terminal',
      navValue: 'terminal',
   },
]

const DeviceTrxDetailsModal = ({
   isOpen,
   setIsOpen,
   transaction,
   selected,
}: Props) => {
   const { InlineRouter, activeTab } = useInlineRouter(apiTrxNavItems[0]?.navValue)
   const [noteValue, setNoteValue] = useState('');
   const isCurrencySwap = transaction?.method === 'SWAP'


   return (
      <CenterModal
         isOpen={isOpen}
         setIsOpen={setIsOpen}
         headerTitle='Transaction Details'>

         <div className='w-full'>

            <div className={`w-full flex-btw py-2 border-b border-shiga-gray-50 ${isCurrencySwap ? 'pb-5' : 'pb-3'}`}>
               <TransactionNarration
                  isDetailsView
                  transaction={transaction}
               />

               <div>
                  <div className={`font-medium text-right text-shiga-green-100`}>
                     + {`${Number(transaction?.amount).toLocaleString()}`}
                  </div>

                  <div className='text-shiga-gray-300 text-sm text-right whitespace-nowrap'>
                     {moment(transaction?.createdAt).format('MMM D, YYYY [at] h:mma')}
                  </div>
               </div>
            </div>

            <div className="pb-3 pt-2">
               <InlineRouter routerType='TAB' navItems={apiTrxNavItems} />
            </div>

            <div className="p-3.5 border border-shiga-gray-50 rounded-xl pt-6">

               <TransactionDetailTabs
                  selected={selected}
                  activeTab={activeTab}
                  transaction={transaction}
               />
            </div>

            <CustomNotePad
               noteValue={noteValue}
               setNoteValue={setNoteValue}
               noteIsRequired={false}
               notePadLeftLabel='Add a Note'
               notePadPlaceholder='Add a few notes to help you later'
            />
         </div>
      </CenterModal>
   )
}

export default DeviceTrxDetailsModal