import { SetStateAction } from "react";
import { Dialog } from "@headlessui/react";
// import DynamicHeadTag from "components/common/DynamicHeadTag";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import ResolveChargeback from "./ResolveChargeback";

type Props = {
  sessionModalOpen: boolean;
  fetchChargebacks: () => void;
  setSessionModalOpen: React.Dispatch<SetStateAction<boolean>>;
};

const ChargebackDetailModal = ({
  sessionModalOpen,
  setSessionModalOpen,
  fetchChargebacks,
}: Props) => {
  const closeModal = () => {
    setSessionModalOpen(false);
  };

  const chargebackDetails = [
    {
      title: "Customer’s Name",
      value: "Seponke Jeremiah",
    },
    {
      title: "Amount",
      value: "NGN 7000",
    },
    {
      title: "Date created",
      value: "Nov 23, 2023 04:08PM",
    },
    {
      title: "Due",
      value: "In a day",
    },
    {
      title: "Status",
      value: "Awaiting feedback",
    },
  ];

  return (
    <div>
      {sessionModalOpen === true && (
        <Dialog
          open={sessionModalOpen}
          className="fixed inset-0 z-50"
          onClose={() => setSessionModalOpen(false)}
        >
          <div className="fixed inset-0 bg-black/40" aria-hidden="true" />

          <div
            className={`mt-24 w-screen bg-white fixed inset-0 rounded-tr-3xl 
                        rounded-tl-3xl overflow-hidden shadow-xl animate__animated animate__faster          
                        ${
                          sessionModalOpen
                            ? "animate__slideInUp"
                            : "animate__slideOutDown"
                        }`}
          >
            {/* <header className="px-6 lg:px-9 py-6 flex items-center justify-between border-b border-gray-100 bg-white z-20 sticky w-full top-0"> */}
            <header className="sticky flex items-center justify-between px-6 lg:px-9 py-6 gap-3 border-b border-gray-100">
              <div className="pl-3 ml-3 text-xl text-shiga-dark-100 font-ojah font-bold">
                Resolve Chargeback
              </div>
              <button
                onClick={closeModal}
                className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
              >
                <IconClose className="m-auto text-white svg-stroke-gray" />
              </button>
            </header>
            <section className="relative  h-[calc(100%-88px)] ">
              <Dialog.Panel className="  h-full">
                <div className="md:flex lg:px-0 h-full">
                  <div className="w-2/6 border-r border-gray-100 pt-8 pb-12 pl-14 pr-2.5">
                    <div className="flex flex-col gap-y-6">
                      <h3 className="text-2xl font-bold">Details</h3>
                      {chargebackDetails.map((item, idx) => {
                        return (
                          <div key={idx * 2}>
                            <p className="text-subtext-gray text-sm ">
                              {item?.title}
                            </p>
                            <p className="text-lg font-semibold">
                              {item?.value}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="w-3/6 pt-8 pl-8 pr-2.5 ">
                    <div className="h-full">
                      <ResolveChargeback />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </section>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default ChargebackDetailModal;
