import { ReactComponent as IconNoChargeback } from "assets/images/icons/refund.svg";

export const NoChargebacks = () => {
  return (
    <div className="px-8 py-20 text-center max-w-md m-auto">
      <div className="px-7 py-6">
        <IconNoChargeback className="w-[75.9px] h-[82.5px] mx-auto" />
      </div>
      <div className="mt-8 text-4xl font-ojah font-bold">
        No Chargebacks yet
      </div>
      <div className="mt-4 text-shiga-gray max-w-xm">
        there is no chargebacks at this time on your business account
      </div>
    </div>
  );
};
