import React from "react";
import { ChargebacksTable } from "./ChargebacksTable";
import { useAppSelector } from "store/hooks";
import { getRefundsStore } from "store/refund/reducer";
import { NoChargebacks } from "./NoChargebacks";

const Chargebacks = () => {
  // const { refunds, isLoadingRefund } = useAppSelector(getRefundsStore);
  const refunds: any[] = [];
  const isLoadingRefund = false;
  return (
    <div>
      {refunds?.length ? (
        <ChargebacksTable
          data={[]}
          loading={isLoadingRefund}
          chargebackData={[]}
        />
      ) : (
        <NoChargebacks />
      )}
    </div>
  );
};

export default Chargebacks;
