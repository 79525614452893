import DeviceMetrics from './DeviceMetrics';
import DeviceTrxTable from './trx-table/DeviceTrxTable';

type Props = {
   selected: any;
}

const DeviceDetails = ({ selected }: Props) => {

   return (
      <div className="w-full">
         <DeviceMetrics selected={selected} />

         <DeviceTrxTable selected={selected} />
      </div>
   )
}

export default DeviceDetails