const Table = (props) => {
  return (
    <div className={`table-container scrollbar-hide  ${props.className} `}>
      <table className={"table table-border" + props.tableClass}>
        {props.children}
      </table>
    </div>
  );
};

const TableHead = (props) => {
  return (
    <thead>
      <tr>{props.children}</tr>
    </thead>
  );
};

const TableBody = (props) => {
  return <tbody className={` ${props.className}`}>{props.children}</tbody>;
};

Table.Head = TableHead;
Table.Body = TableBody;
export default Table;
