import { useEffect, useState } from "react";
import { getIssuedAccounts } from "store/accounts/action";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import moment from "moment";
import Table from "components/common/Table";
import { TableContent } from "components/common/TableContent";
import EmptyAccounts from "./EmptyAccounts";
import { AccountFilterHandler } from "./AccountFilterHandler";
import { processParams } from "utils/processParams";
import useUrlQueryParams from "utils/useUrlQueryParams";
import { useLocation } from "react-router-dom";
import { getDashboardWallets } from "store/dashboard/action";
import { useAppSelector } from "store/hooks";
import { getDashboard } from "store/dashboard/reducer";
import useToolkit from "hooks/useToolkit";
import { ReactComponent as IconCopy } from "assets/images/icons/Copy.svg";
import { ReactComponent as Avatar } from "assets/images/icons/trx-avatar.svg";


const EmptyIssuedAccount = () => {
  const location = useLocation();
  const { gotoUrlQuery, dispatch, useSelector } = useToolkit();

  const { wallets } = useAppSelector(getDashboard);
  const currencies = wallets?.data?.map((item: any) => item.currency);
  const { loadingAccounts, accounttListData } = useSelector((s: any) => s.accounts);

  const queryParams = new URLSearchParams(location.search);
  const urlParams = Object.fromEntries(queryParams.entries());

  const { urlQueryParams } = useUrlQueryParams();
  const [listParams, setListParams] = useState({ page: 1, limit: 9, });

  const fetchLinks = () => {
    let params = { ...listParams };

    params = processParams(urlQueryParams, params, "page", "number", 1, "page");
    params = processParams(urlQueryParams, params, "limit", "number", 9, "limit");
    params = processParams(urlQueryParams, params, "isVirtual", "string", "", "isVirtual");
    params = processParams(urlQueryParams, params, "currency", "string", "NGN", "currency");
    params = processParams(urlQueryParams, params, "search", "string", "", "search");
    params = processParams(urlQueryParams, params, "startDate", "string", "", "startDate");
    params = processParams(urlQueryParams, params, "endDate", "string", "", "endDate");

    setListParams(params);
    dispatch(getIssuedAccounts(params));
  };

  const handleCopy = (textToCopy: string) => {
    copy(textToCopy);
    toast.info("Reference copied");
  };

  useEffect(() => {
    fetchLinks();
    dispatch(getDashboardWallets());
    // eslint-disable-next-line
  }, [location]);


  return (
    <div className="space-y-4 w-full px-10 lg:px-24 ">

      <div className=" flex items-center justify-between self-stretch pb-6 border-b border-b-[#f5f5f5] pt-[45px]">
        <div className="header-text flex flex-col items-start gap-2.5 text-[#343433] font-ojah font-bold text-[32px] leading-normal">
          Accounts
        </div>

        <div className="w-full lg:w-2/4 flex items-center justify-end space-x-5">
          {currencies?.map((currency: string) => {
            const urlFilterValue = urlParams?.currency?.toLowerCase();
            const isDefault = currency === 'NGN';

            const isActive = isDefault ? (!urlFilterValue?.length || urlParams?.currency === 'NGN') : urlParams?.currency === currency;

            return (
              <button
                key={currency}
                onClick={() => gotoUrlQuery({ currency })}
                className={`uppercase font-ojah active:scale-90 transition-transform 
                ${isActive ? "bg-shiga-dark-100 py-1.5 px-3 rounded-full text-white" : "text-shiga-gray-100"}`}>
                {currency}
              </button>
            );
          })}
        </div>
      </div>

      <AccountFilterHandler accounts={accounttListData} />

      <Table>
        <Table.Head>
          <th className="pl-12"> Account Information</th>
          <th>Bank Name</th>
          <th>Reference</th>
          <th>Status</th>
          <th>Date Issued</th>
          <th></th>
        </Table.Head>

        <Table.Body>
          <TableContent
            total={accounttListData?.data?.length || 0}
            loading={loadingAccounts}
            loadingText="Fetching accounts"
            data={accounttListData?.data}
            colspan={6}
            emptyUI={<EmptyAccounts />}
          />

          {!loadingAccounts && accounttListData?.data && accounttListData?.data?.map((data: any, index: number) => (
            <tr
              key={index * 2}
              className="bg-white !py-12 px-4 rounded-md border-b"
            >
              <td className="text-left flex items-center gap-2 py-4">

                <Avatar width={24} height={24} />

                <div className="flex flex-col">
                  <div className="font-medium text-[#343433]">
                    {data.accountNumber}
                  </div>
                  <div className="text-[#99999c] text-sm">
                    {data.accountName}
                  </div>
                </div>
              </td>

              <td>
                <div className="capitalize">
                  {data?.bankName?.toLowerCase()}
                </div>
              </td>

              <td className="text-left">
                <div className="flex">
                  <div className="font-medium text-[#343433]">
                    {data.reference.length > 15
                      ? `${data.reference.slice(0, 15)}`
                      : data.reference}
                  </div>
                  <div
                    className="flex justify-center items-center ml-[8px] cursor-pointer"
                    onClick={() => handleCopy(data.reference)}
                  >
                    <IconCopy className="text-sm text-shiga-gray-300" />

                  </div>
                </div>
              </td>

              <td className="text-left">
                <div className="flex items-center gap-1.5 p-1 rounded-md">
                  <div
                    className={`text-sm font-medium px-2 py-1 rounded-xl ${data.isVirtual
                      ? "text-[#00c46c] border border-[#DCFAEC]"
                      : "text-[#da2f20] border border-[#FFEEE8]"
                      }`}
                  >
                    {data.isVirtual ? "Active" : "Inactive"}
                  </div>
                </div>
              </td>
              <td className="text-left font-medium">
                {moment(data?.createdAt).format("MMM D, YYYY [at] h:mmA")}
              </td>
            </tr>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default EmptyIssuedAccount;
