import Tooltip from "components/common/Tooltip";
import { ReactComponent as IconInfo } from "assets/images/icons/Info.svg";
import useToolkit from "hooks/useToolkit";
import { useEffect } from "react";
import { getTerminalDeviceMetrics } from "store/terminals/action";
import { RootState } from "store/store";
import ContentLoading from "components/common/ContentLoading";


type MetricsProps = {
   tooltipTitle: string;
   tooltipMsg: string;
   figure: string;
}

type Props = {
   selected: any;
}

const DeviceMetrics = ({ selected }: Props) => {
   const { dispatch, useSelector } = useToolkit();
   const { deviceMetricsLoading, deviceMetricsData: metrics } = useSelector((state: RootState) => state.terminals);

   useEffect(() => {
      dispatch(getTerminalDeviceMetrics(selected?.id));
      // eslint-disable-next-line
   }, [])

   const dataList: MetricsProps[] = [
      {
         tooltipTitle: 'Total Collections',
         tooltipMsg: 'Number of transactions completed on this terminal device till date.',
         figure: `${metrics?.data?.successfulTransactions || 'N/A'}`,
      },
      {
         tooltipTitle: 'Current Balance',
         tooltipMsg: 'The funds available on this terminal device right now.',
         figure: 'N/A',
      },
      {
         tooltipTitle: 'Total Settlements',
         tooltipMsg: 'Total payouts from this terminal device to your settlement account',
         figure: `${metrics?.data?.totalPayout || 'N/A'}`,
      },
      {
         tooltipTitle: 'Total Received',
         tooltipMsg: 'Total amount recieved on this terminal device till date.',
         figure: `NGN ${Number(metrics?.data?.totalReceived)?.toLocaleString() || 'N/A'}`,
      },
   ]


   return (
      <div className='border-b border-shiga-gray-50 lg:pb-5'>
         <div className="w-full flex items-center justify-start lg:justify-between flex-wrap lg:flex-nowrap mt-7 lg:mt-10">
            {dataList.map(({ tooltipTitle, tooltipMsg, figure }: MetricsProps) => {
               return (
                  <div key={tooltipTitle} className="w-full lg:w-[22%] mb-8 lg:mb-0">
                     <div className='text-xs lg:text-sm text-shiga-gray-300 font-medium flex items-center mb-3'>

                        <span className="text-plg">
                           {tooltipTitle}
                        </span>

                        <Tooltip message={tooltipMsg} isCompact>
                           <span className='ml-2'>
                              <IconInfo className="text-shiga-dark cursor-pointer" />
                           </span>
                        </Tooltip>

                     </div>

                     <div className='flex font-semibold lg:text-2xl'>
                        <ContentLoading loading={deviceMetricsLoading} data={figure} />
                     </div>

                  </div>
               )
            })}
         </div>

      </div>
   )
}

export default DeviceMetrics