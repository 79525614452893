import { Transaction } from "types/transaction"
import { ReactComponent as IconDownload } from "assets/images/icons/Download.svg";
import { ReactComponent as IconFlag } from "assets/images/icons/flag.svg";
import { ReactComponent as IconInwardTransfer } from "assets/images/icons/arrow-in.svg";
import Dropdown from "utils/dropdown";
import { Menu } from "@headlessui/react";
import SearchBox from "components/common/SearchBox";
import Pagination from "components/common/Pagination";
import CustomTableFilter from "components/common/CustomTableFilter";

type NarrationProps = {
   transaction: Transaction;
   isDetailsView?: boolean;
   isDashboardInline?: boolean;
}

export const TransactionNarration = ({ transaction, isDetailsView, isDashboardInline }: NarrationProps) => {

   return (
      <div className={`flex items-center font-inter ${isDashboardInline && 'mb-3'}`}>

         <div className={`w-10 h-10 rounded-full flexed font-medium mr-4`}>
            <IconInwardTransfer />
         </div>

         <div className="flex flex-col">
            <p className={`text-shiga-gray-300 text-xs capitalize ${isDetailsView ? 'order-2' : 'mb-px'}`}>
               {transaction?.description}
            </p>

            <p className={`text-shiga-dark-100 font-medium text-[14px] capitalize ${isDetailsView && 'order-1 mb-px'}`}>
               N/A
            </p>
         </div>

      </div>
   )
}


export const TransactionType = ({ transaction }: { transaction: Transaction }) => {
   const isBankTransfer = transaction?.method === 'BANK_TRANSFER' || transaction?.channel === 'BANK_TRANSFER';
   const isBillPayment = transaction?.method === 'BILL_PAYMENT';
   const isDirectDebit = transaction?.channel === 'DIRECT_DEBIT';
   const isGiftCard = transaction?.method === 'GIFT_CARD'
   const isApiTrx = transaction?.medium === 'API'
   const isCreditTransaction = transaction?.type === 'CREDIT';
   const isApiCardTrx = isApiTrx && transaction?.channel === 'CARD';
   const isStampDuty = transaction?.description === 'Stamp duty'


   return (
      <div className="border border-shiga-gray-75 rounded-md py-1 px-[5px] flexed w-max text-[10px] capitalize font-inter font-medium">
         {isApiTrx && <span className="text-shiga-gray-300">SDK:&nbsp;</span>}
         {
            isBankTransfer ? 'Bank Transfer'
               : isDirectDebit ? 'Direct Debit'
                  : isBillPayment ? 'Bills Payment'
                     : isGiftCard ? 'Giftcards'
                        : isApiCardTrx ? `Card ${isCreditTransaction ? 'Credit' : 'Debit'}`
                           : isStampDuty ? 'Stamp Charges' : transaction?.method?.toLowerCase() || 'N/A'
         }
      </div>
   )
}

export const TransactionDetailTrigger = ({ transaction }: { transaction: Transaction }) => {

   return (
      <Dropdown dropdownClass="dropdown-right" dropdownIconClassName="rotate-90">
         <></>
         <div>
            {
               transaction.status === "SUCCESSFUL" ?
                  <Menu.Item
                     as="div"
                     onClick={() => { }}
                     className="dropdown-item dropdown-item-icon"
                  >
                     <IconDownload />
                     Share Receipt
                  </Menu.Item> : <></>
            }
            <Menu.Item
               as="div"
               // onClick={toggleRenameCard}
               className="dropdown-item dropdown-item-icon text-shiga-warn">
               <IconFlag />
               Report Transaction
            </Menu.Item>
         </div>
      </Dropdown>
   )
}

export const TransactionFilterHandler = ({ transactions }: { transactions: any }) => {

   return (
      <div className="my-6 flex items-center justify-start lg:justify-between flex-wrap lg:flex-nowrap">
         <div className="w-full lg:w-2/4">
            <CustomTableFilter
               dropdownClass=''
               filters={[
                  {
                     title: "Status",
                     name: "status",
                     dataType: "select",
                     options: [
                        {
                           display: "Show all",
                           payload: "-",
                        },
                        {
                           display: "Successful",
                           payload: "SUCCESSFUL",
                        },
                        {
                           display: "Failed",
                           payload: "FAILED",
                        },
                        // {
                        //    display: "Refunded",
                        //    payload: "REFUNDED",
                        // },
                        // {
                        //    display: 'Reversed',
                        //    payload: 'REVERSED',
                        // }
                     ],
                  },
                  {
                     title: "Currency",
                     name: "currency",
                     dataType: "select",
                     options: [
                        {
                           display: "Show all",
                           payload: "-",
                        },
                        {
                           display: "NGN",
                           payload: "NGN",
                        },
                        {
                           display: "USD",
                           payload: "USD",
                        },
                        {
                           display: 'AUD',
                           payload: 'AUD',
                        },
                        {
                           display: 'GBP',
                           payload: 'GBP',
                        }
                     ],
                  },
                  {
                     title: "Type",
                     name: "method",
                     dataType: "select",
                     options: [
                        {
                           display: "Show all",
                           payload: "-",
                        },
                        {
                           display: "Card",
                           payload: "CARD",
                        },
                        {
                           display: "Bank transfer",
                           payload: "BANK_TRANSFER",
                        },
                        {
                           display: 'Bill payment',
                           payload: 'BILL_PAYMENT',
                        },
                        {
                           display: 'Tution payment',
                           payload: 'TUITION',
                        },
                        {
                           display: 'Deposit',
                           payload: 'DEPOSIT',
                        },
                        {
                           display: 'Refund',
                           payload: 'REFUND',
                        },
                        {
                           display: 'Spendings',
                           payload: 'SPENDINGS',
                        },
                        {
                           display: 'Swap',
                           payload: 'SWAP',
                        },
                        {
                           display: 'Topup',
                           payload: 'TOPUP',
                        },
                        {
                           display: 'Shiga QR',
                           payload: 'SHIGA_QR',
                        },
                        {
                           display: 'Giftcard',
                           payload: 'GIFT_CARD',
                        },
                        {
                           display: 'Stamp Duty',
                           payload: 'STAMP_DUTY',
                        }
                     ]
                  },
                  {
                     title: "Channel",
                     name: "channel",
                     dataType: "select",
                     options: [
                        {
                           display: "Show all",
                           payload: "-",
                        },
                        {
                           display: "Card",
                           payload: "CARD",
                        },
                        {
                           display: "Bank transfer",
                           payload: "BANK_TRANSFER",
                        },
                        {
                           display: 'Direct debit',
                           payload: 'DIRECT_DEBIT',
                        },
                        {
                           display: 'Giftcards',
                           payload: 'GIFT_CARD',
                        },
                        // {
                        //    display: 'API: Card debit',
                        //    payload: 'api_card_debit',
                        // },
                        // {
                        //    display: 'API: Bank Transfer',
                        //    payload: 'api_bank_transfer',
                        // },
                        // {
                        //    display: 'API: Direct debit',
                        //    payload: 'api_direct_debit',
                        // },
                        // {
                        //    display: 'API: Shiga QR',
                        //    payload: 'api_shiga_qr',
                        // }
                     ],
                  },
                  {
                     title: "Date Range",
                     dataType: "date-range",
                     options: {
                        from: {
                           name: "startDate",
                           placeholder: "Start Date",
                        },
                        to: {
                           name: "endDate",
                           placeholder: "End Date",
                        },
                     },
                  },
               ]}
            />
         </div>

         <div className="w-full lg:w-2/4 flex items-center justify-between pt-5 md:pt-0 lg:space-x-5 lg:justify-end">
            <Pagination meta={transactions?.data?.meta} />

            <div className="lg:pb-[26px]">
               <SearchBox
                  name='search'
                  extraUrlParams='page=1'
                  placeholder="Search transactions"
               />
            </div>
         </div>
      </div>
   )
}