import { useEffect } from 'react'
import Table from 'components/common/Table';
import { TableContent } from 'components/common/TableContent';
import { useSelector } from 'react-redux';
import Maybe from 'components/common/Maybe';
import useToolkit from 'hooks/useToolkit';
import moment from 'moment';
import EmptyLogs from "assets/images/icons/lined-globe.svg";
import processParams from 'utils/processParams';
import useUrlQueryParams from 'utils/useUrlQueryParams';
import { RootState } from 'store/store';
import EmptyUI from 'components/common/EmptyUi';
import { useLocation, useNavigate } from 'react-router-dom';
import FilterPagination from './FilterPagination';
import { getDeviceTrxDetails, getTerminalDeviceTrx } from 'store/terminals/action';
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import { currencyFormat } from 'utils/CurrencyFormat';
import DeviceTrxDetailsModal from './trx-details-modal/DeviceTrxDetailsModal';
import { TransactionNarration } from './trx-details-modal/DeviceTrxUtils';


type Props = {
   selected: any;
}

const DeviceTrxTable = ({ selected }: Props) => {
   const location = useLocation();
   const navigate = useNavigate();

   const { useState, dispatch, copyItem } = useToolkit();
   const { urlQueryParams } = useUrlQueryParams();
   const [listParams, setListParams] = useState({
      "page": 1,
      "limit": 9,
   });

   const [detailsShown, setDetailsShown] = useState(false);
   const [activeTrx, setActiveTrx] = useState<any>(null);

   const { deviceTrxLoading: trxLoading, deviceTrxData: transactions, deviceTrxDetailsData } = useSelector((state: RootState) => state.terminals);
   const trxAreValid = trxLoading === false && transactions?.data && transactions?.data?.transactions?.length > 0;

   const fetchTransactions = () => {
      let params = { ...listParams };

      params = processParams(urlQueryParams, params, "page", "number", 1, "page");
      params = processParams(urlQueryParams, params, "limit", "number", 9, "limit");
      params = processParams(urlQueryParams, params, "type", "string", "", "type");
      params = processParams(urlQueryParams, params, "startDate", "string", "", "startDate");
      params = processParams(urlQueryParams, params, "endDate", "string", "", "endDate");

      setListParams(params);
      dispatch(getTerminalDeviceTrx({ id: selected?.id, params }));
   }


   const handleClick = (transaction: any) => {
      setActiveTrx(transaction);
      setDetailsShown(true);
   }

   useEffect(() => {
      navigate(location.pathname);
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      fetchTransactions();
      // eslint-disable-next-line
   }, [location]);

   useEffect(() => {
      if (activeTrx !== null) {
         dispatch(getDeviceTrxDetails({ id: activeTrx?.id, tab: 'details' }));
         dispatch(getDeviceTrxDetails({ id: activeTrx?.id, tab: 'terminal' }));
      }
      // eslint-disable-next-line
   }, [activeTrx]);

   return (

      <>
         <div className="pb-24">

            <FilterPagination data={transactions?.data?.meta} />

            <Table>

               <Maybe condition={(trxLoading === false && !transactions?.data) || (trxAreValid)}>
                  <Table.Head>
                     <th>NARRATION</th>
                     <th>AMOUNT</th>
                     <th>OPERATOR ID</th>
                     <th>REFERENCE</th>
                     <th>DATE CREATED</th>
                  </Table.Head>
               </Maybe>

               <Table.Body>

                  <TableContent
                     total={transactions?.data?.transactions?.length || 0}
                     loading={trxLoading}
                     loadingText="Fetching transactions"
                     data={transactions?.data?.transactions}
                     colspan={5}
                     emptyUI={
                        <EmptyUI
                           icon={EmptyLogs}
                           header="No Transactions Yet"
                           subheader=''
                        />
                     }
                  />

                  {trxLoading === false && transactions?.data?.transactions?.map((transaction: any) => {

                     return (
                        <tr
                           className='cursor-pointer font-inter'
                           key={transaction.id}
                           onClick={() => handleClick(transaction)}>

                           <td>
                              <TransactionNarration transaction={transaction} />
                           </td>

                           <td className={`font-medium text-shiga-green-100`}>
                              + {currencyFormat(transaction?.amount, 'NGN')}
                           </td>

                           <td>
                              {transaction?.operatorId ? `${transaction?.operatorId?.substring(0, 15)}...` : 'N/A'}
                           </td>

                           <td>
                              <div className="flex items-center">

                                 {transaction?.reference ?? 'N/A'}

                                 <Maybe condition={transaction?.reference?.length > 0}>
                                    <button className='ml-2' onClick={(e: any) => {
                                       e.stopPropagation();
                                       copyItem(transaction?.reference, 'Reference');
                                    }}>
                                       <CopyIcon />
                                    </button>
                                 </Maybe>

                              </div>
                           </td>

                           <td>
                              <div className='font-medium'>
                                 {moment(transaction?.createdAt).format('MMM D, YYYY [at] h:mma')}
                              </div>
                           </td>

                        </tr>
                     )
                  }
                  )}
               </Table.Body>
            </Table>
         </div>

         <DeviceTrxDetailsModal
            isOpen={detailsShown}
            selected={selected}
            transaction={activeTrx}
            setIsOpen={setDetailsShown}
            details={deviceTrxDetailsData}
         />
      </>
   )
}

export default DeviceTrxTable