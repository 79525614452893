import React, { useState } from "react";
import { ReactComponent as IconInfoFillYellow } from "assets/images/icons/Info-Yellow.svg";
import CustomNotePad from "components/custom/CustomNotePad";
import ProductImageHandler from "components/updates/ecommerce/product/ProductImageHandler";
import { ButtonLoader } from "components/common/form";
import Maybe from "components/common/Maybe";

const DeclineChargeback = () => {
  const [reason, setReason] = useState("");

  const [uploadedFile, setUploadedFile] = useState<any>(null);

  const handleFileChange = (file: any) => {
    setUploadedFile(file);
  };

  const handleDelete = () => {
    setUploadedFile(null);
  };

  return (
    <div className="pb-12">
      <div>
        <CustomNotePad
          noteValue={reason}
          setNoteValue={setReason}
          noteIsRequired={false}
          notePadLeftLabel="Reason for declining"
          notePadPlaceholder="Add a few notes to help you later"
        />
      </div>
      <div className=" flex flex-col items-start gap-y-4 pr-4 py-4 md:pr-6 md:py-6 w-full">
        <p className="text-md capitalize text-gray-400 font-light font-inter">
          Follow the guidelines below
        </p>
        <div className="flex items-start gap-x-2">
          <IconInfoFillYellow className="flex-none w-[12px] h-[12px] mt-1" />
          <p className="font-inter text-shiga-black text-md  tracking-tighter">
            Please upload all relevant receipts that confirms you delivered
            value to this customer.
          </p>
        </div>
        <div className="flex items-start gap-x-2">
          <IconInfoFillYellow className="flex-none w-[12px] h-[12px] mt-1" />
          <p className="font-inter text-shiga-black text-md tracking-tighter">
            Make sure files uploaded are legible and contain specific
            transaction details to help prevent any losses.
          </p>
        </div>
      </div>

      <div className="my-5">
        <div className="flex justify-between items-center mb-3">
          <p className="text-shiga-gray-100 text-[14px]">
            Upload a proof of transaction{" "}
            <span className="form-input-required">*</span>{" "}
          </p>
          <p className="text-blue-500">See sample file</p>
        </div>

        <div className={`w-full`}>
          <ProductImageHandler
            disabled={false}
            initialSrc={uploadedFile}
            onDelete={handleDelete}
            onFileChange={handleFileChange}
            placeholder="Drag and drop files or click here to upload"
          />
        </div>
        <Maybe condition={uploadedFile !== null}>
          <div className="mt-4 text-sm text-gray-600">
            Uploaded File: <strong>{uploadedFile?.name || uploadedFile}</strong>
          </div>
        </Maybe>
        <Maybe condition={uploadedFile === null}>
          <div className="text-right mt-3">
            <p className="text-shiga-gray-200 font-light text-[13px]">
              Upload only .pdf or .csv files 5MB and below in size
            </p>
          </div>
        </Maybe>
      </div>
      <div className="flex items-end justify-end ">
        <ButtonLoader
          type="submit"
          //   loading={}
          // disabled={
          //   !isFormValid() || inputExceedsTrxAmount || insufficientAmount
          // }
          className="btn btn-lg btn-block btn-primary mt-6 bg-shiga-warn w-auto "
        >
          Decline Chargeback
        </ButtonLoader>
      </div>
    </div>
  );
};

export default DeclineChargeback;
