import { useState } from "react";
import { useToggle } from "hooks/useToggle";
import { useNavigate } from "react-router-dom";
import Table from "components/common/Table";
import { TableContent } from "components/common/TableContent";
import moment from "moment";
import { EmptyState } from "components/common/EmptyState";
import { ReactComponent as EmptyChargebackIcon } from "assets/images/icons/refund.svg";
import CustomTableFilter from "components/common/CustomTableFilter";
import Pagination from "components/common/Pagination";
import SearchBox from "components/common/SearchBox";
import { Chargeback, defaultChargeback } from "types/refund";
import ChargebackDetailModal from "./ChargebackDetailModal";

export const ChargebacksTable: React.FC<{
  chargebackData: any;
  data: any;
  loading: boolean;
}> = ({ chargebackData, data, loading }) => {
  const navigate = useNavigate();
  const [currentchargebacks, setCurrentchargebacks] =
    useState<Chargeback>(defaultChargeback);
  const [showDelete, toggleDelete] = useToggle(false);
  const [filter, setFilter] = useState("");
  const [isModalOopen, setIsModalOopen] = useState(false);

  // const [loading, setLoading] = useState(false);

  const handleShowDelete = (arg: Chargeback) => {
    setCurrentchargebacks(arg);
    toggleDelete();
  };

  const handleClick = (chargebacks: Chargeback) => {
    setIsModalOopen(true);
  };

  //   const chargebacks = useMemo(
  //     () =>
  //       data?.payload.map((item: any) => ({
  //         ...item,
  //         formattedAmount:
  //           item.amountType === "FIXED"
  //             ? currencyFormat(item.amount, item.currency)
  //             : "N/A",
  //         formattedType: item.type.replace("_", " ").toLowerCase(),
  //         createdAt: formatDate(item.createdAt),
  //       })),
  //     [data]
  //   );

  return (
    <div>
      <FilterPaginateSearch data={data} />

      <Table>
        {/* <Maybe
            condition={
              (transactionsLoading === false && !transactions) ||
              transactionsAreValid
            }
          > */}
        <Table.Head>
          <th>CUSTOMER’S NAME</th>
          <th>Amount</th>
          <th>Date Created</th>
          <th>Due</th>
          <th>Status</th>
        </Table.Head>
        {/* </Maybe> */}

        <Table.Body>
          <TableContent
            total={data?.payload?.length || 0}
            loading={loading}
            loadingText="Fetching chargebacks"
            data={data?.payload}
            colspan={6}
            emptyUI={
              <EmptyState
                heading="No Chargebacks yet"
                description="there is no chargebacks at this time on your business account"
                icon={<EmptyChargebackIcon />}
              />
            }
            // customLoader={<Skeleton />}
          />

          {!loading &&
            chargebackData?.map((link: any, idx: number) => {
              const isLinkActive = link?.status === "ACTIVE";

              return (
                <tr
                  style={{ cursor: "pointer" }}
                  key={idx * 2}
                  onClick={() => handleClick(link)}
                >
                  <td>
                    <div className="flex items-center gap-3 cursor-pointer">
                      <p className="text-zinc-800 font-medium !capitalize text-sm">
                        {link?.customer?.firstName ?? "--_--"}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="text-zinc-800 text-sm font-medium cursor-pointer">
                      {link?.formattedAmount ?? "--_--"}
                    </div>
                  </td>
                  <td>
                    <span className="font-medium text-sm">
                      {moment(link?.createdAt).format(
                        "MMM D, YYYY [at] h:mma"
                      ) ?? "--_--"}
                    </span>
                  </td>
                  <td>
                    <div className=" flexed w-max text-[10px] capitalize font-inter font-medium">
                      {link?.formattedType ?? "--_--"}
                    </div>
                  </td>

                  <td>
                    <div>
                      <div
                        className={`border 
                      ${
                        isLinkActive
                          ? "text-shiga-green-100 border-shiga-green-50"
                          : "text-shiga-red-100 border-shiga-red-40"
                      } rounded-md py-1 px-[5px] flexed w-max text-[10px] capitalize font-inter font-medium`}
                      >
                        {link?.status.toLowerCase() ?? "--_--"}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </Table.Body>
      </Table>

      <ChargebackDetailModal
        sessionModalOpen={isModalOopen}
        fetchChargebacks={() => {}}
        setSessionModalOpen={setIsModalOopen}
      />
    </div>
  );
};

const FilterPaginateSearch = ({ data }: { data: any }) => {
  return (
    <div className="flex items-center justify-start lg:justify-between flex-wrap lg:flex-nowrap">
      <div className="w-full lg:w-2/4">
        <CustomTableFilter
          dropdownClass=""
          filters={[
            {
              title: "Status",
              name: "status",
              dataType: "select",
              options: [
                {
                  display: "Show all",
                  payload: "-",
                },
                {
                  display: "Awaiting feedback",
                  payload: "PENDING",
                },
                {
                  display: "Accepted",
                  payload: "SUCCESSFUL",
                },
                {
                  display: "Declined",
                  payload: "FAILED",
                },
              ],
            },
            {
              title: "DUE",
              name: "DUE",
              dataType: "select",
              options: [
                {
                  display: "In Days",
                  payload: "In Days",
                },
                {
                  display: "In Hours",
                  payload: "In Hours",
                },
                {
                  display: "In Minutes",
                  payload: "In Minutes",
                },
              ],
            },
          ]}
        />
      </div>

      <div className="w-full lg:w-2/4 flex items-center justify-between pt-5 md:pt-0 lg:space-x-5 lg:justify-end">
        <Pagination meta={data?.meta} />

        <div className="lg:pb-[26px]">
          <SearchBox
            name="search"
            extraUrlParams="page=1"
            placeholder="Search refunds"
          />
        </div>
      </div>
    </div>
  );
};
