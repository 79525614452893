import PATHS from "NavigationRoute";
import useToolkit from "./useToolkit";

const useRoutes = () => {
  const { kybVerified, isSandboxEnv, isLocalEnv } = useToolkit();
  const sandboxPermit = isSandboxEnv ? true : kybVerified;
  // const isTestAccount = kybVerified && (isLocalEnv || userProfile?.email === 'business1@mailinator.com');

  const dashboardRoutes: SidebarRouteProps[] = [
    {
      title: "Dashboard",
      path: PATHS.DASHBOARD,
      isShown: true,
      permission: true,
    },
    {
      title: "Transactions",
      path: PATHS.TRANSACTIONS.TRANSACTIONS_HOMEPAGE,
      isShown: true,
      permission: sandboxPermit,
    },
    {
      title: "Settlements",
      path: PATHS.SETTLEMENTS.SETTLEMENTS_HOMEPAGE,
      isShown: true,
      permission: sandboxPermit,
    },
    {
      title: isLocalEnv ? "Refunds & Chargebacks" : "Refunds",
      path: PATHS.REFUNDS.REFUNDS_HOMEPAGE,
      isShown: true,
      permission: sandboxPermit,
    },
    {
      title: "Payments",
      path: PATHS.PAYMENTS.PAYMENT_LINK,
      isShown: true,
      permission: sandboxPermit,
    },
    {
      title: "Cards",
      path: PATHS.CARDS.CARDS_HOMEPAGE,
      isShown: true,
      permission: sandboxPermit,
    },
    {
      title: "Accounts",
      path: PATHS.ACCOUNTS,
      isShown: true,
      permission: sandboxPermit,
    },
    {
      title: "Manage Bills",
      path: PATHS.BILLS.AIRTIME,
      isShown: true,
      permission: sandboxPermit,
    },
    {
      title: "Ojah",
      path: "/ojah",
      isShown: true,
      permission: true,
    },
    {
      title: "Terminals",
      path: PATHS.TERMINALS,
      isShown: true,
      permission: sandboxPermit,
    },
    {
      title: "API & Webhooks",
      path: PATHS.WEBHOOKS,
      isShown: true,
      permission: sandboxPermit,
    },
    {
      title: "Settings",
      path: PATHS.SETTINGS.USER_PROFILE,
      isShown: true,
      permission: sandboxPermit,
    },
  ];

  return { dashboardRoutes };
};

export default useRoutes;
