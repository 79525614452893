import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import useToolkit from 'hooks/useToolkit';
import Maybe from 'components/common/Maybe';
import { RootState } from 'store/store';

type Props = {
   transaction: any;
   activeTab: string;
   selected: any;
}

const TransactionDetailTabs = ({ transaction, activeTab, selected }: Props) => {
   const { copyItem, showValue, useSelector } = useToolkit();
   const { deviceTrxDetailsData: data } = useSelector((state: RootState) => state.terminals)
   const showDetails = activeTab === 'details';
   const showTerminal = activeTab === 'terminal';

   return (
      <div className="w-full">

         <Maybe condition={showDetails}>
            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  Fee
               </div>

               <div className="transaction-detail-inline-value capitalize">
                  {data?.fee} NGN
               </div>
            </div>

            <div className={`transaction-detail-inline-wrapper`}>
               <div className="transaction-detail-inline-key">
                  Transaction ID
               </div>

               <div className="transaction-detail-inline-value">
                  <div className='flex items-center font-medium'>
                     {transaction?.id?.substring(0, 15)}

                     <button className='ml-1.5' onClick={() => copyItem(transaction?.id, 'Transaction ID')}>
                        <CopyIcon width={20} />
                     </button>
                  </div>
               </div>
            </div>

            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  You get
               </div>

               <div className="flex items-center">
                  {Number(data?.amount)?.toLocaleString()} NGN
               </div>
            </div>
         </Maybe>

         <Maybe condition={showTerminal}>

            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  Device Name
               </div>

               <div className="flex items-center">
                  {showValue(selected?.name)}
               </div>
            </div>

            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  Device Code
               </div>

               <div className="flex items-center">
                  {showValue(selected?.code)}
               </div>
            </div>

            <div className={`transaction-detail-inline-wrapper`}>
               <div className="transaction-detail-inline-key">
                  Agent ID
               </div>

               <div className="transaction-detail-inline-value">
                  <div className='flex items-center font-medium'>
                     {transaction?.operatorId?.substring(0, 15)}

                     <button className='ml-1.5' onClick={() => copyItem(transaction?.operatorId, 'Agent ID')}>
                        <CopyIcon width={20} />
                     </button>
                  </div>
               </div>
            </div>

         </Maybe>

      </div>
   )
}

export default TransactionDetailTabs